<script>
import Multiselect from "@vueform/multiselect";
import API from "@/plugins/axios-method";
import flatPickr from "vue-flatpickr-component";
import {BFormInput} from "bootstrap-vue-3";
import {COMMERCIAL, COORDINATOR, SUPERVISOR, USER} from "../../helpers/constant/roles";
import Modal from "@/components/modal.vue";
export default {
  name: "filter-form",
  components: {BFormInput, Multiselect, flatPickr, Modal},
  props: {
    value: {},
  },
  data(){
    return {
      visible: false,
      memberTypeValue: null,
      memberTypes: [],
      cities: [],
      isSubmitted: false,
      regionCriteria: [],
      regions: [],
      selectedRegionCriteria: null,
      regionValue: null,
      ageCriteria: [],
      age: null,
      selectedAgeCriteria: null,
      ageValue: null,
      fundingStatusCriteria: [],
      fundingStatus: [
        { label: 'Approuvé', value: 2 },
        { label: 'En attente', value: 1 },
        { label: 'Rejeté', value: 3 },
      ],
      selectedFundingStatusCriteria: null,
      fundingStatusValue: null,
      userTypeCriteria: [],
      selectedUserTypeCriteria: null,
      userType: [
        { label: "Adhérent", value: USER },
        { label: "Commercial", value: COMMERCIAL },
        { label: "Superviseur", value: SUPERVISOR },
        { label: "Coordinateur", value: COORDINATOR },
      ],
      userTypeValue: null,
      subscriptionDateCriteria: [],
      subscriptionDate: null,
      selectedSubscriptionDateCriteria: null,
      subscriptionDateValue: null,
      subscriptionCountCriteria: [],
      subscriptionCount: null,
      selectedSubscriptionCountCriteria: null,
      subscriptionCountValue: null,
      savingStatusCriteria: [],
      selectedSavingStatusCriteria: null,
      createdSinceValue: null,

    }
  },
  watch: {
    value(newValue) {
      this.visible = newValue
    },
  },
  mounted() {
    this.getCriteria()
    this.getRegions()
    this.getMemberType()
  },
  methods: {
    submitFilter(){
      // const selectedCitiesString = this.cities.filter(city => this.selectedCities.some(selectedCity => city.uniqId === selectedCity.split('/api/cities/')[1])).map(c => c.id).join(',')
      this.isSubmitted = true
      this.closeSearchModal()
      this.$emit('filterSubmitted', this.setRequestBody())
      this.isSubmitted = false
    },
    getMemberType(){
      API.get('/constant/member_type')
          .then(response => {
            response.data.memberType.map((memberType) => {
              this.memberTypes.push({
                value: memberType,
                label: memberType
              })
            })
          })
    },
    getCitiesWithUsers() {
      API.get('/cities_with_users')
          .then(response => {
            response.data['hydra:member'].map((city) => {
              this.cities.push({
                value: city['@id'],
                label: city.name,
                id: city.id,
                uniqId: city.uniqId,
                // label: city.name + ` (${city.userCount})`,
              })
            })
          })
          .finally(() => {
            this.$emit('cities-loaded', true)
          })
    },
    getRegions(){
      API.get('/regions')
          .then(response => {
            response.data['hydra:member'].map((region) => {
              this.regions.push({
                label: region.name,
                value: region.id,
              })
            })
          })
          .finally(() => {
            this.$emit('cities-loaded', true)
          })
    },
    getCriteria(){
      API.get('/constant/search_criteria')
          .then(response => {
            const {region, ageGroup, userRole, savingStatus, subscriptionDate, fundingRequestStatus, activeSubscriptionCount} = response.data.searchCriteria
            this.setRegionCriteria(region)
            this.setAgeCriteria(ageGroup)
            this.setUserTypeCriteria(userRole)
            this.setSubscriptionDateCriteria(subscriptionDate)
            this.setSubscriptionCountCriteria(activeSubscriptionCount)
            this.setFundingRequestStatusCriteria(fundingRequestStatus)
            this.setSavingStatusCriteria(savingStatus)
          })
    },
    setRegionCriteria(region = []){
      region.map((r) => {
        this.regionCriteria.push({
          label: r.name,
          value: r['@id'],
        })
      })

      this.selectedRegionCriteria = this.regionCriteria[0].value
    },
    setAgeCriteria(ageGroup = []){
      ageGroup.map((age) => {
        this.ageCriteria.push({
          label: age.name,
          value: age['@id'],
        })
      })

      this.selectedAgeCriteria = this.ageCriteria[0].value
    },
    setUserTypeCriteria(userType = []){
      userType.map((u) => {
        this.userTypeCriteria.push({
          label: u.name,
          value: u['@id'],
        })
      })
      this.selectedUserTypeCriteria = this.userTypeCriteria[0].value
    },
    setSubscriptionDateCriteria(subscriptionDate = []){
      subscriptionDate.map((s) => {
        this.subscriptionDateCriteria.push({
          label: s.name,
          value: s['@id'],
        })
      })

      this.selectedSubscriptionDateCriteria = this.subscriptionDateCriteria[0].value
    },
    setSubscriptionCountCriteria(subscriptionCount = []){
      subscriptionCount.map((s) => {
        this.subscriptionCountCriteria.push({
          label: s.name,
          value: s['@id'],
        })
      })

      this.selectedSubscriptionCountCriteria = this.subscriptionCountCriteria[0].value
    },
    setFundingRequestStatusCriteria(fundingRequestStatus = []){
      fundingRequestStatus.map((f) => {
        this.fundingStatusCriteria.push({
          label: f.name,
          value: f['@id'],
        })
      })
      this.selectedFundingStatusCriteria = this.fundingStatusCriteria[0].value
    },
    setSavingStatusCriteria(savingStatus = []){
      savingStatus.map((s) => {
        this.savingStatusCriteria.push({
          label: s.name,
          value: s['@id'],
        })
      })
      this.selectedSavingStatusCriteria = this.savingStatusCriteria[0].value
    },

    setRequestBody(){
      let body = {}

      if(this.regionValue && this.regionValue.toString() !== ""){
        body.region = {
          SearchAttributeCriteria: this.selectedRegionCriteria,
          value: this.regionValue.toString()
        }
      }

      if (this.ageValue){
        body.ageGroup = {
          SearchAttributeCriteria: this.selectedAgeCriteria,
          value: '' + this.ageValue
        }
      }

      if (this.userTypeValue){
        body.userRole = {
          SearchAttributeCriteria: this.selectedUserTypeCriteria,
          value: this.userTypeValue
        }
      }

      if (this.fundingStatusValue){
        body.fundingRequestStatus = {
          SearchAttributeCriteria: this.selectedFundingStatusCriteria,
          value: '' + this.fundingStatusValue
        }
      }

      if (this.subscriptionDateValue){
        body.subscriptionDate = {
          SearchAttributeCriteria: this.selectedSubscriptionDateCriteria,
          value: this.subscriptionDateValue
        }
      }

      if (this.memberTypeValue){
        body.savingStatus = {
          SearchAttributeCriteria: this.selectedSavingStatusCriteria,
          value: this.memberTypeValue
        }
      }

      if (this.subscriptionCountValue){
        body.activeSubscriptionCount = {
          SearchAttributeCriteria: this.selectedSubscriptionCountCriteria,
          value: '' + this.subscriptionCountValue
        }
      }

      if (this.createdSinceValue){
        body.createdSince = {
          value: '' + this.createdSinceValue
        }
      }

      return body
    },
    clearForm(){
      this.regionValue = null
      this.ageValue = null
      this.userTypeValue = null
      this.fundingStatusValue = null
      this.subscriptionDateValue = null
      this.memberTypeValue = null
      this.subscriptionCountValue = null
      this.createdSinceValue = null
    },
    clearSubscriptionDate(){
      this.subscriptionDateValue = null
    },
    changeCreatedSince(event){
      if(event){
        this.subscriptionDateValue = null
        // this.createdSinceValue = new Date(new Date().setMonth(new Date().getMonth() - 3))
      }
    }

  },

}
</script>

<template>
  <Modal @closeModal="closeSearchModal" v-model="visible">
    <div class="card-header d-flex justify-content-between mt-2">
      <h5 class="card-title mb-0">Filtrer les utilisateurs</h5>
      <p title="Supprimez les champs" class="cursor-pointer"><span @click="clearForm"><i class="bx bx-trash h2 text-danger"></i></span></p>
    </div>
    <div class="bg-white">
      <form @submit.prevent="submitFilter">
        <div class="row g-3 justify-content-end align-items-center my-2">
          <div class="col-sm-6">
            <div>
              <label for="member">Critère</label>
              <Multiselect
                  required
                  class="form-control"
                  v-model="selectedRegionCriteria"
                  :close-on-select="true"
                  :searchable="true"
                  :options="regionCriteria" />
            </div>
          </div>
          <!--end col-->
          <div class="col-sm-6">
            <div>
              <label for="cities">Region</label>
              <Multiselect
                  class="form-control"
                  v-model="regionValue"
                  :close-on-select="true"
                  :searchable="true"
                  :mode="'tags'"
                  :options="regions" />
            </div>
          </div>
        </div>
        <div class="row g-3 justify-content-end align-items-center mb-4">
          <div class="col-sm-6">
            <div>
              <label for="member">Critère</label>
              <Multiselect
                  required
                  class="form-control"
                  v-model="selectedAgeCriteria"
                  :close-on-select="true"
                  :searchable="true"
                  :options="ageCriteria" />
            </div>
          </div>
          <!--end col-->
          <div class="col-sm-6">
            <div>
              <label for="cities">Groupe d'Age</label>
              <b-form-input type="number" v-model="ageValue" />
            </div>
          </div>
        </div>
        <div class="row g-3 justify-content-end align-items-center mb-4">
          <div class="col-sm-6">
            <div>
              <label for="member">Critère</label>
              <Multiselect
                  class="form-control"
                  v-model="selectedFundingStatusCriteria"
                  :close-on-select="true"
                  :searchable="true"
                  :options="fundingStatusCriteria" />
            </div>
          </div>
          <!--end col-->
          <div class="col-sm-6">
            <div>
              <label for="cities">Status de financement</label>
              <Multiselect
                  class="form-control"
                  v-model="fundingStatusValue"
                  :close-on-select="true"
                  :searchable="true"
                  :options="fundingStatus"/>
            </div>
          </div>
        </div>
        <div class="row g-3 justify-content-end align-items-center mb-4">
          <div class="col-sm-6">
            <div>
              <label for="member">Critère</label>
              <Multiselect
                  required
                  class="form-control"
                  v-model="selectedUserTypeCriteria"
                  :close-on-select="true"
                  :searchable="true"
                  :options="userTypeCriteria" />
            </div>
          </div>
          <!--end col-->
          <div class="col-sm-6">
            <div>
              <label for="cities">Role d'utilisateurs</label>
              <Multiselect
                  class="form-control"
                  v-model="userTypeValue"
                  :close-on-select="true"
                  :searchable="true"
                  :options="userType" />
            </div>
          </div>
        </div>
        <div class="row g-3 justify-content-end align-items-center mb-4">
          <div class="col-sm-6">
            <div>
              <label for="member">Critère</label>
              <Multiselect
                  required
                  class="form-control"
                  v-model="selectedSavingStatusCriteria"
                  :close-on-select="true"
                  :searchable="true"
                  :options="savingStatusCriteria" />
            </div>
          </div>
          <!--end col-->
          <div class="col-sm-6">
            <div>
              <label for="cities">Status Epargne</label>
              <Multiselect
                  class="form-control"
                  v-model="memberTypeValue"
                  :close-on-select="true"
                  :searchable="true"
                  :options="memberTypes" />
            </div>
          </div>
        </div>
        <div class="row g-3 justify-content-end align-items-center mb-4">
          <div class="col-sm-6">
            <div>
              <label for="member">Critère</label>
              <Multiselect
                  required
                  class="form-control"
                  v-model="selectedSubscriptionDateCriteria"
                  :close-on-select="true"
                  :searchable="true"
                  :options="subscriptionDateCriteria" />
            </div>
          </div>
          <!--end col-->
          <div class="col-sm-6">
            <div>
              <label for="cities">Date de souscription</label>
              <flat-pickr placeholder="Choissez une date" v-model="subscriptionDateValue"
                          class="form-control flatpickr-input" id="date"></flat-pickr>
            </div>
          </div>
        </div>
        <div class="row g-3 justify-content-end align-items-center mb-4">
          <div class="col-sm-6">
            <div>
              <label for="member">Critère</label>
              <Multiselect
                  required
                  class="form-control"
                  v-model="selectedSubscriptionCountCriteria"
                  :close-on-select="true"
                  :searchable="true"
                  :options="subscriptionCountCriteria" />
            </div>
          </div>
          <!--end col-->
          <div class="col-sm-6">
            <div>
              <label for="cities">Nombre de souscription</label>
              <b-form-input type="number" v-model="subscriptionCountValue" />
            </div>
          </div>
        </div>
        <div class="row g-3 justify-content-end align-items-center mb-4">
          <div class="col-sm-6">
            <div class="form-check mb-2">
              <b-form-checkbox @change="changeCreatedSince($event)" v-model="createdSinceValue" id="formCheck1" />
              <label for="formCheck1">
                Uniquement les utilisateurs inscrits il y a moins de 3 mois
              </label>
            </div>
          </div>
        </div>
        <div class="mt-4">
          <div>
            <button :disabled="isSubmitted" class="btn btn-primary w-100" type="submit">
              <div v-if="isSubmitted" style="width: 20px; height: 20px" class="text-white spinner-border text-primary avatar-sm" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
              <span v-if="!isSubmitted"><i class="ri-equalizer-fill me-1 align-bottom"></i> Filtrer</span>
            </button>
          </div>
        </div>
      </form>
    </div>
  </Modal>
</template>

<style scoped>

</style>